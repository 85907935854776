<template>
  <div class="invite" v-if="isShow">
    <p class="invite-tips">请对本次服务进行评价</p>
    <div class="invite-img flex">
      <div @click="makeResult('great')">
        <img src="../../assets/images/invite_icon_on.png" v-if="formData.score=='great'" alt="">
        <img src="../../assets/images/invite_icon_off.png" v-else  alt="">
      </div>
      <div class="invite-img_bad" @click="makeResult('bad')">
        <img src="../../assets/images/invite_icon_on.png" v-if="formData.score=='bad'" alt="">
        <img src="../../assets/images/invite_icon_off.png" v-else alt="">
      </div>
    </div>
    <p class="invite-result" >
      <span v-if="formData.score!=''">{{formData.score=='great'?"满意":"不满意"}}</span>
    </p>
    <el-input class="invite-input" placeholder="评价备注" maxlength="200" show-word-limit v-model="formData.content" type="textarea" :rows="6"></el-input>

    <div class="invite-btn">
      <span class="invite-btn_common pointer" @click="cancel">关闭评价</span>
      <span class="invite-btn_primary pointer" @click="submit">提交评价</span>
    </div>
  </div>
</template>
<script>
  export default {
    name:"",
    props:{
      isShow:{
        type:Boolean,
        default:false
      },
      requestMethods:{
        default(){
          return null
        }
      }
    },
    data() {
      return {
        formData:{
          score:"",
          content:""
        }
      }
    },
    watch:{
      isShow(e){
        if(e){
          for(let key in this.formData){
            this.formData[key] = ''
          }
        }
      }
    },
    methods:{
      cancel(){
        this.$emit("update:isShow",false)
      },
      submit(){
        if(this.formData.score=="") return this.$message.warning("请先进行打分");
        // 发送评价请求
        this.requestMethods&&this.requestMethods(this.formData);
      },
      // 评分
      makeResult(e){
        this.formData.score = e;
      },
    }
  }
</script>
<style scoped lang="scss">
@import "@/assets/style/basic.scss";
  .invite{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    padding: 15px ;
    z-index: 1000;
    &-tips{
      margin-bottom: 15px;
      font-size: 18px;
      padding: 5px 0 20px;
      box-sizing: border-box;
      border-bottom: 1px solid #f0f0f0;
    }
    &-img{
      justify-content: center;
      margin: 30px 0 20px;
      div+div{
        margin-left: 60px;
      }
      img{
        height:60px;
        width: 60px;
        cursor: pointer;
      }
      &_bad{
        img{
          transform: rotate(180deg);
        }
      }
    }
    &-result{
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      color: #999;
      margin-bottom: 10px;
    }
    &-input{
      margin-bottom: 30px;
    }
    &-btn{
      margin:0 auto;
      text-align: center;
      span{
        display: inline-block;
        width: 80px;
        height: 30px;
        line-height: 30px;
      }
      span+span{
        margin-left: 20px;
      }
      &_common{
        border: 1px solid #ccc;
        border-radius: 4px;
        color: #999;
      }
      &_primary{
        @include background-main-color($theme_sky);
        border-radius: 4px;
        color: #fff;
      }
    }
  }
</style>